
import { Swiper, SwiperSlide } from 'swiper-vue2';
import { arrayOf, shape, string } from 'vue-types';
import { THEME_LIGHT } from '~/constants/PageThemes';

const STORE_FRONT_ACCESS_TOKEN = '2c95d1535909434a770b0ac4570d2b24';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        productHandle: string(),
        addToCart: string(),
        footerNotes: arrayOf(
            shape({
                icon: string(),
                text: string(),
            })
        ),
    },
    data() {
        return {
            cart: null,
            product: null,
            selectedVariantId: null,
            swiper: null,
            THEME_LIGHT,
            activeSlide: 0,
            swiperOptions: {
                slidesPerView: 1,
                direction: 'horizontal',
                loop: true,
            },
        };
    },
    computed: {
        selectedVariant() {
            return this.product?.variants?.find(({ id }) => id === this.selectedVariantId);
        },
        images() {
            return this.product?.images || [];
        },
        price() {
            return this.selectedVariant?.price;
        },
    },
    mounted() {
        if (this.productHandle) {
            this.initShopify();
        }
    },
    methods: {
        slideTo(i) {
            this.swiper.slideToLoop(i);
        },
        onSwiper(swiper) {
            this.swiper = swiper;
        },
        sliderPrev() {
            if (this.swiper) this.swiper.slidePrev();
        },
        sliderNext() {
            if (this.swiper) this.swiper.slideNext();
        },
        onSlideChange() {
            if (!this.swiper) return;
            const { realIndex } = this.swiper;
            this.activeSlide = realIndex - 1;
        },
        async addVariantToCart() {
            this.$gtag('event', 'addtocart', {
                event_category: 'product',
                event_label: this.selectedVariant.title,
            });

            await this.cart.addVariantToCart({ id: this.selectedVariantId }, 1, true);
            this.cart.open();
        },
        async initShopify() {
            const sdk = await import('@shopify/buy-button-js');

            const client = sdk.buildClient({
                domain: 'reveals-clothing.myshopify.com',
                storefrontAccessToken: STORE_FRONT_ACCESS_TOKEN,
            });

            client.product.fetchByHandle(this.productHandle).then((p) => {
                this.product = p;
                this.selectedVariantId = p.variants?.[0]?.id;
            });

            sdk.UI.init(client)
                .createComponent('cart', {
                    moneyFormat: '€{{amount}}',
                    options: {
                        cart: {
                            styles: {
                                button: {
                                    'font-family': 'Quantico, sans-serif',
                                    ':hover': {
                                        'background-color': '#000000',
                                    },
                                    'background-color': '#000000',
                                    ':focus': {
                                        'background-color': '#000000',
                                    },
                                },
                            },
                            text: {
                                total: 'Subtotal',
                                button: 'Checkout',
                                currency: 'EUR',
                            },
                            popup: false,
                            googleFonts: ['Quantico'],
                            events: {
                                openCheckout: (cart) => {
                                    this.$gtag('event', 'checkout', {
                                        event_category: 'click',
                                    });
                                },
                            },
                        },
                        toggle: {
                            styles: {
                                toggle: {
                                    'font-family': 'Quantico, sans-serif',
                                    'background-color': '#000000',
                                    ':hover': {
                                        'background-color': '#000000',
                                    },
                                    ':focus': {
                                        'background-color': '#000000',
                                    },
                                },
                            },
                            googleFonts: ['Quantico'],
                        },
                    },
                })
                .then((c) => {
                    this.cart = c;
                });
        },
    },
};
